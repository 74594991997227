import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "iconVisible", "iconHidden"]

  toggle(e) {
    e.preventDefault();

    this.inputTarget.type = this.inputTarget.type === 'password' ? 'text' : 'password'

    this.iconVisibleTarget.classList.toggle("hidden");
    this.iconHiddenTarget.classList.toggle("hidden");
  }
}
