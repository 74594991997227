import { Controller } from "@hotwired/stimulus"
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ["transitionElement", "container"];
  static values  = { open: Boolean }

  show(event) {
    event.preventDefault()

    if (this.hasContainerTarget) {
      this.containerTarget.classList.remove("hidden")
    }

    this.transitionElementTargets.forEach(element => {
      enter(element)
    })
  }

  hide(event) {
    let promises = []
    event.preventDefault()

    this.transitionElementTargets.forEach(element => {
      promises.push(
        leave(element)
      )
    })

    if (this.hasContainerTarget) {
      Promise.all(promises).then(() => {
        this.containerTarget.classList.add("hidden")
      })
    }
  }

  toggle(event) {
    if (this.openValue) {
      this.hide(event)
    }
    else {
      this.show(event)
    }

    this.openValue = !this.openValue
  }
}
