import { Controller } from "@hotwired/stimulus"
import { useTransition } from 'stimulus-use';

export default class extends Controller {
  static targets = ["content"];
  static classes = ["displayed"];

  static values  = {
    withTransition:        Boolean,
    transitionEnterActive: String,
    transitionEnterFrom:   String,
    transitionEnterTo:     String,
    transitionLeaveActive: String,
    transitionLeaveFrom:   String,
    transitionLeaveTo:     String,
  }

  connect() {
    this.#enableTransition()
  }

  toggle(e) {
    e.preventDefault();

    this.contentTargets.forEach((element) => {
      element.classList.toggle(this.displayedClass);
    })

    if (this.withTransitionValue) {
      this.toggleTransition()
    }
  }

  #enableTransition() {
    if (!this.withTransitionValue) { return }

    useTransition(this, {
      element:     this.contentTarget,
      enterActive: this.transitionEnterActiveValue,
      enterFrom:   this.transitionEnterFromValue,
      enterTo:     this.transitionEnterToValue,
      leaveActive: this.transitionLeaveActiveValue,
      leaveFrom:   this.transitionLeaveFromValue,
      leaveTo:     this.transitionLeaveToValue,
      hiddenClass: this.displayedClass
    });
  }
}
