import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["hidden"]
  static targets = ["relatedFields"]

  updateRelatedFieldsVisibility(event) {
    const choice = event.currentTarget.value

    this.relatedFieldsTargets.forEach(relatedFields => {
      if (relatedFields.dataset.relatedChoice == choice) {
        relatedFields.classList.toggle(this.hiddenClass)
      }
    })
  }
}
