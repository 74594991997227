import { Controller } from "@hotwired/stimulus"
import $ from "jquery";
import select2 from 'select2';

export default class extends Controller {
  connect() {
    select2();

    $(this.element).select2();

    this.fixJQuery3_6Focus();
    this.openSelect2OnFocus();
  }

  fixJQuery3_6Focus() {
    // Since jQuery 3.6, focus is broken when the dropdown opens
    $(this.element).on('select2:open', () => {
      let allFound = document.querySelectorAll('.select2-container--open .select2-search__field');
      allFound[allFound.length - 1].focus();
    });
  }

  openSelect2OnFocus() {
    $(this.element.parentElement).on("focus", ".select2", function (e) {
      if (e.originalEvent) {
        var s2element = $(this).siblings("select:enabled");
        s2element.select2("open");

        // Set focus back to select2 element on closing.
        s2element.on("select2:closing", function () {
          if (s2element.val()) s2element.select2("focus");
        });
      }
    });
  }
}
