import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { autoScroll: Boolean }

  connect() {
    if (this.autoScrollValue) { this.#scroll() }
  }

  scroll() {
    this.#scroll()
  }

  #scroll() {
    this.element.scrollIntoView({ block: "center", inline: "center" })
  }
}
