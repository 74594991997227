import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["hidden"]
  static targets = ["choice", "option", "optionWrapper"]

  static values  = {
    choicesWithOption: Array
  }

  updateOptionVisibility() {
    if (this.choicesWithOptionValue.includes(this.choiceTarget.value)) {
      this.optionWrapperTarget.classList.remove(this.hiddenClass)
    } else {
      this.optionWrapperTarget.classList.add(this.hiddenClass)
      this.optionTarget.checked = false
    }
  }
}
