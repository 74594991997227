import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = ["eventsTimeline", "userConfirmations"];
  static values  = { id: Number, channel: String  }

  connect() {
    this.alertChannel = consumer.subscriptions.create(
      {
        channel: this.channelValue,
        id:      this.idValue
      },
      {
        received: this._received_from_channel.bind(this)
      }
    );
  }

  disconnect () {
    this.alertChannel.unsubscribe()
  }

  _received_from_channel(data) {
    let target;

    if (data.event == 'notification_sent') {
      target = this.eventsTimelineTarget;
    }
    else if (data.event == 'user_alert_confirmed') {
      target = this.userConfirmationsTarget;
    }

    target.outerHTML = data.html;
  }
}
