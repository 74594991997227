import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["hidden", "loading"]
  static targets = ["buttonLabelEnabled", "buttonLabelDisabled"]

  static values = {
    buttonDisabled: { type: Boolean, default: false },
    fileName:       String,
    fileUrl:        String,
  }

  start(event) {
    event.preventDefault()
    if (this.buttonDisabledValue) { return }

    this.#disableButton()

    fetch(this.fileUrlValue)
      .then(function (response) { return response.blob() })
      .then(this.#downloadFromBlob.bind(this))
      .catch(this.#handleError.bind(this))
  }

  #disableButton() {
    this.buttonDisabledValue = true

    this.element.classList.add(this.loadingClass)
    this.buttonLabelEnabledTarget.classList.add(this.hiddenClass)
    this.buttonLabelDisabledTarget.classList.remove(this.hiddenClass)
  }

  #enableButton() {
    this.buttonDisabledValue = false

    this.buttonLabelEnabledTarget.classList.remove(this.hiddenClass)
    this.buttonLabelDisabledTarget.classList.add(this.hiddenClass)
  }

  #downloadFromBlob(blob) {
    const url  = window.URL.createObjectURL(blob)
    const link = document.createElement('a')

    link.href          = url
    link.download      = this.fileNameValue
    link.style.display = "none"

    document.body.appendChild(link)
    link.click()
    window.URL.revokeObjectURL(url)

    this.#enableButton()
  }

  #handleError() {
    alert("Erreur lors du téléchargement du fichier.")
    this.#enableButton()
  }
}
