import { Controller } from "@hotwired/stimulus"
import $ from "jquery";
import select2 from 'select2';

export default class extends Controller {
  static targets = ["cancelIcon", "details", "form", "selectTag"]
  static classes = ["hidden"]

  connect() {
    select2();
  }

  hideForm(e) {
    e.preventDefault()
    this.formTarget.classList.add(this.hiddenClass)
    this.detailsTarget.classList.remove(this.hiddenClass)
  }

  revealForm(e) {
    e.preventDefault()

    this.formTarget.classList.remove(this.hiddenClass)
    this.detailsTarget.classList.add(this.hiddenClass)
  }

  revealFormAndOpenSelectTag(e) {
    this.revealForm(e)
    $(`#${this.selectTagTarget.id}`).select2("open")
  }
}
