import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["pwaIphone"]

  connect() {
    if (this.#pwaIphoneDetected()) {
      this.element.classList.add(this.pwaIphoneClass)
    }
  }

  #pwaIphoneDetected() {
    return this.#pwaDetected() && this.#iphoneDetected()
  }

  #iphoneDetected() {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone/.test(userAgent);
  }

  #pwaDetected() {
    return (
      window.matchMedia('(display-mode: standalone)').matches ||
      window.matchMedia('(display-mode: fullscreen)').matches
    )
  }
}
